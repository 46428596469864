import axios from "axios";
//设置请求得基准地址
const $http = axios.create();
//设置请求头

const authorization =()=>{
    const userInfo =  localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const token = userInfo && userInfo.token
    return {
        Authorization:token ? "Bearer " + token : ""
    }
}

$http.interceptors.request.use(config => {
    // 给请求头加上Authorization,authJWT的字段,值为token
    config.headers = {
        ...config.headers,
        ...authorization()
    }
    return config
}, (error) => {
    console.log("Promise err")
    return Promise.reject(error);
});

//导出
export default $http;