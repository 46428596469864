import React, {useState,useEffect} from 'react';
import {Row, Col, Divider} from "antd";
import ScoreCard from "../../../components/SocreCard";
import ScorePlots from "../../../components/ScorePlots";
import ScoreUsers from "../../../components/ScoreUsers";
import {TeamOutlined} from "@ant-design/icons";
import "antd/dist/antd.min.css"
import "./index.css"
import $http from "../../../requests/requests";

const sortCount = (args) =>{
    const timeData = args.sort(function(a,b){
        return a.t > b.t ? 1:-1
    })
    return timeData
}

const Home = () => {
    const {group,name} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    const [dashboard,setDashboard] = useState([])
    const [scoreTotal,setScoreTotal] = useState({push:0, pull:0})
    useEffect(()=>{
        const params = {
            getType:"initDashboard",
            group,
        };
        $http.post(`user/${params.getType}`,params).then(
            res=>{
                if (res.data.ret === 0){
                    setScoreTotal({push:res.data.data.month_push, 
                        pull:res.data.data.month_pull})
                    setDashboard(sortCount(res.data.data.count))
                }
            }
        )
        
    },[group])
    return (
            <>
                <div className="dashboardTitle">
                    <TeamOutlined/>
                    <span className="titleContent">{name}</span>
                ️</div>
                <Row gutter={8}>
                    <Col span={12}><ScoreCard list={({title:"月发单",number:scoreTotal.push,icon:"push"})}/></Col>
                    <Col span={12}><ScoreCard list={({title:"月接单",number:scoreTotal.pull,icon:"pull"})}/></Col>
                </Row>
                <Divider orientation="left" plain>
                    最近30天分布图
                </Divider>
                <ScorePlots data={dashboard}/>
            <ScoreUsers/>
            </>
    );
};

export default Home;