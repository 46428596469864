import React from 'react';
import {Form, Input,Button,message} from "antd";
import md5 from "md5"
import {UserOutlined,LockOutlined} from "@ant-design/icons";
import "./index.css"
import $http from "../../requests/requests";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const Login = () => {

    const navigate = useNavigate();

    const onFinish = (values) => {
        values.password = md5("just4.vip@"+values.password)
        const params = {
            username:values.username,
            password:values.password,
            createTime:moment().format('x')*1,
        };
        $http.post("user/sysUserLogin",params).then(
            res=>{
                if (res.data.ret !== 0) {
                    message.config({top:150})
                    message.error("用户名或密码错误",1)
                }else {
                    localStorage.setItem("userInfo",JSON.stringify(res.data.data))
                    navigate("/main",{replace:true})
                }
            }
        )
    }

    return (
        <div style={{background:'rgb(35,39,65)',height:'100vh'}} >
            <div className="formContainer">
                <div className="loginTitle">WX车队统计系统</div>
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '请输入用户名！' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '密码格式不正确！' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="密码"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;