import React, {useState,useEffect} from 'react';
import {DatePicker, Space, Table,Input, Button} from "antd";
import {getLocalScoreList, setLocalScoreList} from "../../../utils";
import { DownloadOutlined } from '@ant-design/icons';
import $http from '../../../requests/requests';
import moment from "moment";
import {CustomerServiceOutlined, SoundOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
import 'moment/locale/zh-cn';
import './index.css'
import html2canvas from "html2canvas";
const {Search} = Input

function disabledDate(current) {
    // Can not select days before today and today
    return current < moment().subtract(6,'month') || current > moment().add( 'month');
}
const ListTopBar= (props) => {
    const {name} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    const columns = [
        {
            title:<span><TeamOutlined />{name}</span>,
            dataIndex: 'name',
            width:'60%',
            ellipsis: true,
            key:'key',
        },
        {
            title: <SoundOutlined />,
            dataIndex: 'push',
            width:'20%',
            key:'key',
            align:'center',
            sorter: (b, a) => a.push - b.push,
        },
        {
            title: <CustomerServiceOutlined />,
            dataIndex: 'pull',
            width:'20%',
            align:'center',
            key:'key',
            sorter: (b, a) => a.pull - b.pull,
        },
    ];
    const [list,setList] = useState([])

    useEffect(()=>{
        setList(props.data)
    },[props])

    const searchList  = (val) => {
        if(!val) return setList([...props.data])
        let data = [...props.data]
        data = data.filter(item => {
            return item.name.includes(val)
        })
        setList(data)
    }
    const handleDatePicker = async (_,date) => {
        setList(await props.getScoreList(date))
    }

    const exportAsImage = async (element, imageFileName) => {
        const canvas = await html2canvas(element,{
            allowTaint: true,
            removeContainer: true,
            backgroundColor: null,
            imageTimeout: 15000,
            logging: true,
            scale: 2,
            useCORS: true
          });
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
        };
        const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;
        
        fakeLink.href = blob;
        
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        
        fakeLink.remove();
        };

    return (
        <>
            <div className='topBar'>
                <Space>
                    <DatePicker picker="month"  style={{ minWidth: '100px'}} allowClear={false}
                                defaultValue={moment()} onChange={handleDatePicker}  disabledDate={disabledDate}/>
                    <Search prefix={<UserOutlined />}
                            placeholder="search"
                            onSearch={val => {searchList(val)}}
                            onCancel={() => {setList(props.data)}}
                            allowClear
                            style={{ minWidth: '120px' }} />
                    <Button icon={<DownloadOutlined />} size="middle" onClick={() => exportAsImage(document.getElementById("root"), 
                    moment().format("YYYY年MM月")+name)}/>
                </Space>
            </div>
            <Table columns={columns} dataSource={list}  pagination={false}/>
        </>
    );
}



const Score = ()=>{
    const {group} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    const [list,setList] = useState([])
    const getScoreList = (date=moment().format("YYYY-MM")) => {
        const scoreList = getLocalScoreList(date)
        if (scoreList){
            setList(scoreList)
            return
        }
        const params = {
            getType:"getScoreList",
            group:group,
            month: date,
        };

        $http.post(`user/${params.getType}`,params).then(
            res => {
                if(res.data.ret === 0){
                    setList(res.data.data)
                    setLocalScoreList(date,res.data.data)
                }
            },
            () =>{
                setList(scoreList)
            }
        )
    };
    useEffect(()=>{
        getScoreList()
    },[])
    return (
        <div>
            <ListTopBar data={list} getScoreList={getScoreList}/>
        </div>
    );
}

export default Score;