import {INIT_SCORE_LIST} from "./actionType";
const initState = "hello"
export default function scoreList_reducer(perState=initState,action){
    const {type,value} = action
    switch (type){
        case INIT_SCORE_LIST:
            console.log("reducer:",value)
            return value
        default:
            return perState
    }
}