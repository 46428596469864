import React, {useState} from 'react';
import {Divider, List, Input, message, Typography, Tag} from "antd";
import $http from "../../requests/requests";

const {Text} = Typography
const ScoreUsers = () => {
    const {group} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    const [phoneList,setPhoneList] = useState([{name: "请输入要查询的电话号码"}])

    const handleChange = (event) => {
        event.target.value||setPhoneList([{name: "请输入要查询的电话号码"}])
    }
    const handleSearch = (value) => {
        if (!value) return
        let number = value.replace(/\D/g, "")
        if (number.length === 10) {
            number = "1" + number
        }
        if (number.length === 11) {
            const params = {
                getType:"getPhoneInfo",
                group:group,
                phone:number*1,
            };
            $http.post(`user/${params.getType}`,params).then(
                res => {
                    if (res.data.ret === 0) {
                        if (res.data.data.length > 0) {
                            setPhoneList(res.data.data)
                        } else {
                            setPhoneList([{name: "查无此号码数据"}])
                        }
                    }
                }
            );
        } else {
            message.config({top: 450})
            message.error("电话号码格式错误")
        }
    }

    return (
        <>
            <Divider orientation="left" plain>号码查询</Divider>
            <List itemLayout={"vertical"}
                  size="small"
                  header={<Input.Search allowClear style={{minWidth: '200px'}} maxLength={20}
                                        onSearch={handleSearch}
                                        onChange={handleChange}
                  />}
                  bordered
                  dataSource={phoneList}
                  renderItem={item =>
                      <List.Item>
                          <Text ellipsis style={{width: "75%"}}>{item.name}</Text>
                          <Tag color="#F50" style={{float: "right"}}>{item.count || 0}</Tag>
                      </List.Item>}
            />
        </>
    )
};

export default ScoreUsers;