// eslint-disable-next-line no-unused-vars
import React from "react";
import moment from "moment";


export const countScoreList = (list)=>{
    let count = {pull:0,push:0}
    // eslint-disable-next-line array-callback-return
    list.map((item)=>{
        count.push += item.push
        count.pull += item.pull
    })
    return count
}

export const getLocalScoreList = (date=moment().format("YYYY-MM"))=>{
    const {group} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    //获取当前月份
    const nowMonth = moment().format("YYYY-MM")
    //判断时间是否为当前月份 是单月返回 群id+"@this" 不是单月返回 群id+@+月份
    const key = (date !== nowMonth)?group+"@"+date:group+"@this"
    const list = JSON.parse(localStorage.getItem(key))
    if (!list)return false
    if (key.includes("this")){
        if (Date.now() - list.createTime > 60000*5){
            return false
        }
    }
    return list.list
}

export const setLocalScoreList = (date=moment().format("YYYY-MM"),list)=>{
    const {group} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    //获取当前月份
    const noMonth = moment().format("YYYY-MM")
    //判断时间是否为当前月份 是单月返回 群id+"@this" 不是单月返回 群id+@+月份
    date !== noMonth?
        localStorage.setItem(group+"@"+date,JSON.stringify({createTime:Date.now(),list} )):
        localStorage.setItem(group+"@this",JSON.stringify({createTime:Date.now(),list} ))
}
