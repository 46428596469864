
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom'
import Login from "../views/Login";
import ScoreSanBox from "../views/ScoreSanBox";
import Home from "../views/ScoreSanBox/Home";
import Score from "../views/ScoreSanBox/Score";
import User from "../views/ScoreSanBox/User";
import NoPermission from "../views/ScoreSanBox/NoPermission";
import React from "react";
import AuthPage from "../components/AuthPage";

export default function IndexRouter() {

    return (
        <HashRouter>
            <Routes>
                <Route path="/login" element={<Login/>} />
                <Route path="/main" element={<AuthPage><ScoreSanBox/></AuthPage>}>
                    <Route path="home" element={<Home/>}/>
                    <Route path="score" element={<Score/>}/>
                    <Route path="user" element={<User/>}/>
                    <Route path="" element={<Home/>}/>
                    <Route path="*" element={<NoPermission/>}/>
                </Route>
                <Route path="*" element={<Navigate to="/main" replace/>} />
            </Routes>
        </HashRouter>
    );
};
