import React, {Component} from 'react';
import {ConfigProvider} from "antd";
import locale from 'antd/lib/locale/zh_CN';
import IndexRouter from "./router/IndexRouter";

class App extends Component {
    render() {

        return (
            <ConfigProvider locale={locale}>
                <IndexRouter></IndexRouter>
            </ConfigProvider>
        );
    }
}

export default App;