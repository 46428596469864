import { Dropdown, Button, Row, Col, Affix} from "antd";
import {HomeOutlined, PoweroffOutlined, SettingOutlined, UnorderedListOutlined} from "@ant-design/icons";
import "./index.css"
import {useNavigate} from "react-router-dom";
const iconList =
    {
        "/main/home": <HomeOutlined/>,
        "/main/score": <UnorderedListOutlined/>,
        "/main/user": <SettingOutlined/>,
        "/login": <PoweroffOutlined/>
    }

const Headers = () => {
    let navigate = useNavigate();

    const menuList = [
        {"id": 1,"url":"/main/home","title":"首页"},
        {"id": 2,"url":"/main/score","title":"报表"},
        {"id": 3,"url":"/main/user","title":"设置"},
        {"id": 4,"url":"/login","title":"登出"},
        ]

    
    const onClick= ({key}) =>{
        if (key === "/login") {
            localStorage.clear();
        }
        navigate(key);

    }
    

    const items = 
        menuList.map((item)=>
            ({ 
                icon:iconList[item.url],
                key: item.url,
                className:"topMenu item",
                onClick,
                label: (
                <a href={item.href}>
                {item.title}
              </a>)
            })
        )
    ;


    // const mu = (
    //     <Menu >
    //         {
    //             menuList.map((item)=>
    //                 <Menu.Item  className="topMenu item"  key={item.url} icon={iconList[item.url]}  onClick={() => {
    //                     if (item.url === "/logout") {
    //                         localStorage.clear();
    //                         navigate("/login",{ replace: true } )
    //                     } else {
    //                         navigate(item.url)
    //                     }

    //                 }}>{item.title} </Menu.Item>
    //             )
    //         }
    //     </Menu>
    // )
               
    return (
        <Affix>
        <div className="topHeader">
            <Row>
                <Col span={16}><span className="logo">WX<span>车队统计系统</span></span></Col>
                <Col span={8}>
                <span className="headerDropdown">
                <Dropdown menu={{items,selectable: true,
      defaultSelectedKeys: ['/main/home'],theme:"dark"}} placement="bottomRight">
                    <Button ghost size="small"><UnorderedListOutlined/></Button>
                </Dropdown>
                </span>
                </Col>
            </Row>
        </div>
        </Affix>
    )

};

export default Headers;