import React from 'react';
import {Outlet} from "react-router-dom";
import "./index.css"
import {Layout} from "antd";
import Headers from "../../components/Headers";

const {Content,Footer} = Layout
const ScoreSanBox = () => {
    return (
        
        <Layout className="layout">
            <Headers />
            <Content style={{padding: '0 10px', margin: '16px 0'}}>
                <div className="site-layout-content" >
                    <Outlet />
                </div>
            </Content>
            <Footer className="footerStyle">
                ©2024 Created by yydsplus.com
                <p>Data update by: 2022-02-22 16:31</p>
            </Footer>
        </Layout>
    );
};

export default ScoreSanBox;