import React, {useState,useEffect} from 'react';
import {Card} from 'antd'
import {
    CustomerServiceTwoTone,
    SoundTwoTone
} from "@ant-design/icons";
import CountUp from 'react-countup'
import './index.css'

const iconList =
    {
        "push": <SoundTwoTone/>,
        "pull": <CustomerServiceTwoTone/>
    }


const ScoreCard = (props) => {

    const [list,setList] = useState({})
    useEffect(()=>{
            setList({title:props.list.title,number:props.list.number*1,icon:props.list.icon})
    },[props])
    return (
        <Card
            className='numberCard'
            bordered={true}
            hoverable={true}
        >
      <span className='iconWarp'>
          {iconList[list.icon]}
      </span>
            <div className='content'>
                <p className='title'>{list.title || ''}</p>
                <p className='number'>
                    <CountUp
                        start={0}
                        end={list.number}
                        duration={3}
                        useEasing
                        useGrouping
                        separator=","
                    />
                </p>
            </div>
        </Card>
    );
};

export default ScoreCard;