import React,{useState,useEffect} from 'react';
import { Column } from '@ant-design/plots'
const ScorePlots = (props) => {
    const [list,setList] = useState([])
    useEffect(()=>{
        setList(props.data)
    },[props])

    const config = {
        data:list,
        isGroup: true,
        xField: 'date',
        yField: 'p',
        seriesField: 'name',
    };
    return (
        <Column height={220} style={{paddingBottom:"28px"}} {...config}/>
    );
};

export default ScorePlots;