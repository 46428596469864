import React from 'react';
import {Form, Button,Card,Divider, Input, message} from "antd";
import md5 from "md5"
import $http from "../../../requests/requests";
import {useNavigate} from "react-router-dom";
const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
const User = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const {group,username,name} = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):""
    const onFinish = ({password_old,password_new}) => {
        password_old = md5("just4.vip@"+password_old)
        password_new = md5("just4.vip@"+password_new)
        const params = {
            getType:"restPassword",
            password_new,
            password_old,
            group,
        };
        $http.post(`user/${params.getType}`,params).then(
            res=>{
                if (res.data.ret !== 0) {
                    message.config({top:200})
                    message.error("旧密码不正确",1)
                }else {
                    message.config({top:150})
                    message.success("修改成功,请使用新密码登陆！")
                    localStorage.clear()
                    navigate("/login",{replace:true});
                }
            }
        )
    }
    return (
        <div>
            <Divider orientation="left" plain>账户设置</Divider>
            <Card>
                <p>群名称：{name}</p>
                <p>用户名：{username}</p>
            </Card>
            <Divider orientation="left" plain>修改密码</Divider>
            <Card>
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                    labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      style={{
                        maxWidth: 600,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                >
                    <Form.Item
                        label="旧密码"
                        name="password_old"
                        rules={[{ required: true, message: '请输入旧密码！' }]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="password_new"
                        rules={[
                            { required: true, pattern: /^(?![^a-zA-Z]+$)(?!\\D+$).{8,16}$/, message: '8-16位字符，必须包含字母和数字' },
                            ({ getFieldValue }) =>({
                                validator(rule, value){
                                    if (!value || getFieldValue("password_old") !== value){
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("旧密码与新密码不能相同！")
                                }
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        label="确认新密码"
                        name="password_confirm"
                        rules={[
                            ({ getFieldValue }) =>({
                                required: true,
                                validator(rule, value){
                                    if (!value || getFieldValue("password_new") === value){
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("新密码与确认新密码不匹配！")
                                }
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                                修 改
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default User;